<template>
  <v-lazy>
    <v-container>
      <v-card elevation="2" outlined shaped>
        <!-- <v-card-title class="justify-center">
          {{ measurementTypeData.name }}
        </v-card-title> -->

        <v-container>
          <MeasurementSplineChart
            v-bind:id="measurementTypeData.name"
            :chartData="measurementTypeChartData"
            :name="measurementTypeData.name"
            :unit="measurementTypeData.unit"
            :locationData="locationData"
          ></MeasurementSplineChart>
        </v-container>

      </v-card>
    </v-container>
  </v-lazy>
</template>

<script>
import MeasurementSplineChart from "./MeasurementSplineChart.vue";

export default {
  name: "MeasurementType",

  components: {
    MeasurementSplineChart,
  },

  props: {
    locationData: {},
    measurementTypeChartData: {},
    measurementTypeData: {},
  },
};
</script>