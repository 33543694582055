// src/firebaseConfig.js
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyBxVYQbPx0kdNx-7jGsE4BDCCGlFBWmWEw",
    authDomain: "moisture-sensor-b5192.firebaseapp.com",
    databaseURL: "https://moisture-sensor-b5192.firebaseio.com",
    projectId: "moisture-sensor-b5192",
    storageBucket: "moisture-sensor-b5192.appspot.com",
    messagingSenderId: "541526459697",
    appId: "1:541526459697:web:58dfcfdc17b39630ecdc10"
  };

// Initialize Firebase
export default firebase.initializeApp(firebaseConfig);