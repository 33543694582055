<template>
  <v-lazy>
    <highcharts :options="this.chartOptions()"></highcharts>
  </v-lazy>
</template>

<script>
import { Chart } from "highcharts-vue";
import ChartHelper from "../helper/chartHelper.js"
import Highcharts from "highcharts";
import exportingInit from "highcharts/modules/exporting";

exportingInit(Highcharts);

export default {
  name: "MeasurementSplineChart",

  components: {
    highcharts: Chart,
  },

  data() {
    return {
      seriesData: [],
    };
  },

  methods: {
    chartOptions() {
      return {
        chart: {
          backgroundColor: "#1E1E1E",
          resetZoomButton: {
            position: {
              align: "center",
            },
            theme: {
              fill: "#FFFFFFB3",
            },
          },
          type: "spline",
          zoomType: "x",
        },
        colors: [
          "#16785C",
          "#2B1678",
          "#781632",
          "#637816",
          "#166378",
          "#5C1678",
          "#782B16",
          "#327816",
        ],
        exporting: {
          buttons: {
            contextButton: {
              menuItems: ["viewFullscreen", "downloadJPEG"],
              theme: {
                fill: "#FFFFFFB3",
              },
            },
          },
        },
        legend: {
          itemStyle: { color: "#FFFFFFB3" },
          enabled: true,
          verticalAlign: "top",
        },
        series: this.seriesData,
        time: { timezoneOffset: new Date().getTimezoneOffset() },
        title: {
          text: this.name,
          style: { color: "#FFFFFFB3" },
        },
        xAxis: {
          gridLineColor: "#FFFFFFB3",
          labels: { style: { color: "#FFFFFFB3" } },
          lineColor: "#FFFFFFB3",
          minorGridLineColor: "#FFFFFFB3",
          minorTickColor: "#FFFFFFB3",
          showLastLabel: true,
          tickColor: "#FFFFFFB3",
          title: {
            style: { color: "#FFFFFFB3" },
          },
          type: "datetime",
        },
        yAxis: {
          gridLineColor: "#FFFFFFB3",
          labels: { style: { color: "#FFFFFFB3" } },
          lineColor: "#FFFFFFB3",
          minorGridLineColor: "#FFFFFFB3",
          minorTickColor: "#FFFFFFB3",
          plotBands: ChartHelper.getYAxisPlotBands(this.name),
          tickColor: "#FFFFFFB3",
          title: {
            style: { color: "#FFFFFFB3" },
            text: ChartHelper.getYAxisLabel(this.name, this.unit),
          },
        },
      };
    },
  },

  mounted() {
    for (let sensorId in this.chartData) {
      this.seriesData.push({
        data: this.chartData[sensorId],
        name: this.locationData[sensorId],
      });
    }
  },

  props: {
    chartData: Object,
    locationData: Object,
    name: String,
    sensorsData: Object,
    unit: String,
  },
};
</script>