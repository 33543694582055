<template>
  <v-lazy>
    <v-container>
      <v-card elevation="2" outlined shaped>
        <v-card-title>
          {{ sensorData.location }}
        </v-card-title>
        <v-card-subtitle>
          <span style="float: right; font-size: 12px">{{
            sensorData.sensorId
          }}</span>
          <span style="float: left; font-size: 12px"
            >Updated:
            {{ getLatestMeasurementTimestamp }}
          </span>
        </v-card-subtitle>

        <v-container class="text-center" style="overflow: auto">
          <v-row
            style="justify-content: center"
            v-if="hasTemperatureAndHumidity"
          >
            <v-col cols="12" sm="6" md="4">
              <GaugeChartTemperature
                :dataPoint="
                  sensorMeasurementsData.latestMeasurement.temperature[1]
                "
                :name="sensorMeasurementsData.measurementTypes.temperature.name"
                :unit="sensorMeasurementsData.measurementTypes.temperature.unit"
              >
              </GaugeChartTemperature>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <GaugeChartRelativeHumidity
                :dataPoint="
                  sensorMeasurementsData.latestMeasurement.relativeHumidity[1]
                "
                :name="
                  sensorMeasurementsData.measurementTypes.relativeHumidity.name
                "
                :unit="
                  sensorMeasurementsData.measurementTypes.relativeHumidity.unit
                "
              ></GaugeChartRelativeHumidity>
            </v-col>
          </v-row>

          <!-- <v-chip
            class="ma-1"
            color="white"
            disabled
            outlined
            v-bind:key="key"
            v-for="(measurement, key) in sensorData.measurementTypes"
            :input-value="false"
          >
            {{ sensorData.measurementTypes[key].name }}:
            {{
              Math.round(
                sensorMeasurementsData.chartData[
                  sensorData.measurementTypes[key].type
                ][
                  sensorMeasurementsData.chartData[
                    sensorData.measurementTypes[key].type
                  ].length - 1
                ][1] * 10
              ) /
                10 +
              " " +
              sensorData.measurementTypes[key].unit
            }}
          </v-chip> -->
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small rounded @click="show = !show">
            Show Graphs<v-icon>{{
              show ? "mdi-chevron-up" : "mdi-chevron-down"
            }}</v-icon>
          </v-btn>
        </v-card-actions>

        <v-expand-transition>
          <v-lazy>
            <v-carousel
              height="450"
              hide-delimiter-background
              touchless
              v-show="show"
              :show-arrows="true"
            >
              <v-carousel-item
                v-for="(measurements, key) in sensorMeasurementsData.chartData"
                v-bind:key="key"
              >
                <SensorSplineChart
                  v-bind:id="sensorMeasurementsData.sensorId + key"
                  :chartData="measurements"
                  :name="sensorMeasurementsData.measurementTypes[key].name"
                  :sensorId="sensorMeasurementsData.sensorId"
                  :unit="sensorMeasurementsData.measurementTypes[key].unit"
                ></SensorSplineChart>
              </v-carousel-item>
            </v-carousel>
          </v-lazy>
        </v-expand-transition>
      </v-card>
    </v-container>
  </v-lazy>
</template>

<script>
import GaugeChartRelativeHumidity from "./charts/GaugeChartRelativeHumidity.vue";
import GaugeChartTemperature from "./charts/GaugeChartTemperature.vue";
import SensorSplineChart from "./SensorSplineChart.vue";

export default {
  name: "Sensor",

  components: {
    GaugeChartRelativeHumidity,
    GaugeChartTemperature,
    SensorSplineChart,
  },

  computed: {
    getLatestMeasurementTimestamp() {
      if (this.sensorMeasurementsData) {
        let lastUpdated = new Date(
          this.sensorMeasurementsData.latestMeasurement.timestamp
        );

        return (
          lastUpdated.toLocaleDateString("en-CA") +
          " " +
          lastUpdated.toLocaleTimeString("en-CA")
        );
      }
      return null;
    },
    hasTemperatureAndHumidity() {
      return (
        Object.keys(this.sensorMeasurementsData.measurementTypes).includes(
          "temperature"
        ) &&
        Object.keys(this.sensorMeasurementsData.measurementTypes).includes(
          "relativeHumidity"
        )
      );
    },
  },

  data: () => ({
    show: false,
  }),

  props: {
    sensorData: {},
    sensorMeasurementsData: {},
  },
};
</script>