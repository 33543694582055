class ChartHelper {
  static getYAxisLabel(name, unit) {
    return unit === '' ? name : name.concat(' (', unit, ')')
  }

  static getYAxisPlotBands(name) {
    switch (name) {
      case 'Internal VCC':
        return [
          {
            color: '#4CAF501A', // Success/Green
            from: 4500,
            to: 3750,
          },
          {
            color: '#FFC1071A', // Warning/Orange
            from: 3250,
            to: 3750,
          },
          {
            color: '#FF52521A', // Error/Red
            from: 0,
            to: 3750,
          }
        ];
      case 'Relative Humidity':
        return [
          {
            color: '#2196F31A', // Info/Blue
            from: 0,
            to: 30,
          },
          {
            color: '#4CAF501A', // Success/Green
            from: 30,
            to: 50,
          },
          {
            color: '#FF52521A', // Error/Red
            from: 50,
            to: 100,
          }
        ]
      case 'Temperature':
        return [
          {
            color: '#2196F31A', // Info/Blue
            from: -100,
            to: 20,
          },
          {
            color: '#4CAF501A', // Success/Green
            from: 20,
            to: 25,
          },
          {
            color: '#FFC1071A', // Warning/Orange
            from: 25,
            to: 30,
          },
          {
            color: '#FF52521A', // Error/Red
            from: 30,
            to: 100,
          }
        ];
      default:
        return
    }
  }
}

export default ChartHelper
