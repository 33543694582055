<template>
  <highcharts :options="this.chartOptions()"></highcharts>
</template>

<script>
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import More from "highcharts/highcharts-more";

More(Highcharts);

export default {
  name: "GaugeChartRelativeHumidity",

  components: {
    highcharts: Chart,
  },

  methods: {
    chartOptions() {
      return {
        chart: {
          backgroundColor: "#1E1E1E",
          height: "320",
          resetZoomButton: {
            position: {
              align: "center",
            },
            theme: {
              fill: "#FFFFFFB3",
            },
          },
          type: "gauge",
        },
        exporting: { enabled: false },
        legend: { enabled: false },
        pane: {
          startAngle: -150,
          endAngle: 150,
          background: [
            {
              backgroundColor: {
                linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                stops: [
                  [0, "#FFF"],
                  [1, "#333"],
                ],
              },
              borderWidth: 0,
              outerRadius: "109%",
            },
            {
              backgroundColor: {
                linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                stops: [
                  [0, "#333"],
                  [1, "#FFF"],
                ],
              },
              borderWidth: 1,
              outerRadius: "107%",
            },
            {
              // default background
            },
            {
              backgroundColor: "#DDD",
              borderWidth: 0,
              outerRadius: "105%",
              innerRadius: "103%",
            },
          ],
        },
        series: [
          {
            color: "#16785C",
            data: [this.dataPoint],
            dataLabels: {
              formatter: () => {
                return this.dataPoint + " " + this.unit;
              },
            },
            name: this.name,
          },
        ],
        title: {
          text: undefined,
        },
        tooltip: { enabled: false },
        yAxis: {
          max: 100,
          min: 0,

          minorTickInterval: "auto",
          minorTickWidth: 1,
          minorTickLength: 10,
          minorTickPosition: "inside",
          minorTickColor: "#1E1E1E",

          tickPixelInterval: 30,
          tickWidth: 2,
          tickPosition: "inside",
          tickLength: 10,
          tickColor: "#1E1E1E",

          labels: {
            step: 2,
            rotation: "auto",
            style: { color: "#1E1E1E" },
          },
          plotBands: [
            {
              color: "#2196F380", // Info/Blue
              from: 0,
              to: 30,
            },
            {
              color: "#4CAF5080", // Success/Green
              from: 30,
              to: 55,
            },
            {
              color: "#FF525280", // Error/Red
              from: 55,
              to: 100,
            },
          ],
          title: {
            text: this.name,
            style: { color: "#1E1E1E" },
          },
        },
      };
    },
  },

  props: {
    dataPoint: Number,
    name: String,
    unit: String,
  },
};
</script>