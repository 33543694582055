<template>
  <highcharts :options="this.chartOptions()"></highcharts>
</template>

<script>
import { Chart } from "highcharts-vue";
import ChartHelper from "../helper/chartHelper.js"
import Highcharts from "highcharts";
import exportingInit from "highcharts/modules/exporting";

exportingInit(Highcharts);

export default {
  name: "SensorSplineChart",

  components: {
    highcharts: Chart,
  },

  data() {
    return {
      splineChartData: [[]],
    };
  },

  methods: {
    chartOptions() {
      return {
        chart: {
          backgroundColor: "#1E1E1E",
          resetZoomButton: {
            position: {
              align: "center",
            },
            theme: {
              fill: "#FFFFFFB3",
            },
          },
          type: "spline",
          zoomType: "x",
        },
        exporting: {
          buttons: {
            contextButton: {
              theme: {
                fill: "#FFFFFFB3",
              },
            },
          },
        },
        legend: { enabled: false },
        series: [
          {
            name: this.name,
            color: "#16785C",
            data: this.splineChartData,
          },
        ],
        time: { timezoneOffset: new Date().getTimezoneOffset() },
        title: {
          text: this.name,
          style: { color: "#FFFFFFB3" },
        },
        xAxis: {
          labels: { style: { color: "#FFFFFFB3" } },
          gridLineColor: "#FFFFFFB3",
          lineColor: "#FFFFFFB3",
          title: {
            style: { color: "#FFFFFFB3" },
          },
          type: "datetime",
          showLastLabel: true,
        },
        yAxis: {
          labels: { style: { color: "#FFFFFFB3" } },
          plotBands: ChartHelper.getYAxisPlotBands(this.name),
          title: {
            text: ChartHelper.getYAxisLabel(this.name, this.unit),
            style: { color: "#FFFFFFB3" },
          },
        },
      };
    },
  },

  mounted() {
    this.splineChartData = this.chartData;
  },

  props: {
    chartData: Array,
    sensorId: String,
    name: String,
    unit: String,
  },
};
</script>