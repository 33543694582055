<template>
  <v-app>
    <v-app-bar app color="primary" dark dense hide-on-scroll>
      <v-img
        alt="Moist Cloud Logo"
        class="shrink mr-2"
        contain
        src="@/assets/logo.svg"
        transition="scale-transition"
        width="48"
        height="48"
      />

      <v-app-bar-title alt="Moist Cloud"> Moist Cloud </v-app-bar-title>

      <v-spacer></v-spacer>

      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
    </v-app-bar>

    <v-navigation-drawer app color="#1E1E1E" temporary v-model="drawer">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h8"> Owners </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list>
        <v-list-item-group v-model="activeOwner">
          <v-list-item
            @click="reloadData(owner)"
            v-bind:key="owner"
            v-bind:value="owner"
            v-for="owner in owners"
          >
            <v-list-item-icon>
              <v-icon>mdi-flower</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ owner }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <v-divider></v-divider>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h8">
            Chart Presentation
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list dense>
        <v-list-item-group v-model="byMeasurementType">
          <v-list-item
            v-bind:value="false"
            @click="setByMeasurementType(false)"
          >
            <v-list-item-icon>
              <v-icon>mdi-chart-line</v-icon>
            </v-list-item-icon>
            <v-list-item-title>By sensor</v-list-item-title>
          </v-list-item>

          <v-list-item v-bind:value="true" @click="setByMeasurementType(true)">
            <v-list-item-icon>
              <v-icon>mdi-chart-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-title>By measurement</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-dialog v-model="loading" fullscreen>
      <v-container
        fluid
        fill-height
        style="background-color: rgba(0, 0, 0, 0.9)"
      >
        <v-layout justify-center align-center>
          <v-progress-circular indeterminate color="primary">
          </v-progress-circular>
        </v-layout>
      </v-container>
    </v-dialog>

    <v-snackbar v-model="snackbar" min-width="300">
      {{ "Data loaded for " + activeOwner }}

      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-container style="max-width: 1280px" v-if="ready">
      <v-lazy>
        <v-main v-if="bySensor">
          <Sensor
            v-for="sensorData in data.sensorsData"
            v-bind:key="sensorData.sensorId"
            :sensorMeasurementsData="
              data.measurementsData.bySensor[sensorData.sensorId]
            "
            :sensorData="sensorData"
          ></Sensor>
        </v-main>
      </v-lazy>

      <v-lazy>
        <v-main v-if="byMeasurementType">
          <MeasurementType
            v-bind:key="key"
            v-for="(measurementTypeChartData, key) in data.measurementsData
              .byMeasurementType.chartData"
            :locationData="data.measurementsData.byMeasurementType.locations"
            :measurementTypeChartData="measurementTypeChartData"
            :measurementTypeData="
              data.measurementsData.byMeasurementType.measurementTypes[key]
            "
          >
          </MeasurementType>
        </v-main>
      </v-lazy>
    </v-container>

    <v-bottom-navigation app color="primary" grow v-model="byMeasurementType">
      <v-btn v-bind:value="false">
        <span>Sensor</span>

        <v-icon>mdi-chart-line</v-icon>
      </v-btn>

      <v-btn v-bind:value="true">
        <span>Measurement</span>

        <v-icon>mdi-chart-multiple</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-app>
</template>

<script>
import axios from "axios";
import firebase from "./firebaseInit";
import MeasurementType from "./components/MeasurementType.vue";
import Sensor from "./components/Sensor.vue";

const apiUrl =
  "https://us-central1-moisture-sensor-b5192.cloudfunctions.net/app/api";
// const apiUrl =
//   "http://localhost:5001/moisture-sensor-b5192/us-central1/app/api";

export default {
  name: "App",

  components: {
    MeasurementType,
    Sensor,
  },

  computed: {
    bySensor: function () {
      return !this.byMeasurementType;
    },
    ready: function () {
      return !this.loading;
    },
  },

  created() {
    if (localStorage.activeOwner) {
      this.activeOwner = localStorage.activeOwner;
    }
    this.authenticate();
  },

  data: () => ({
    activeOwner: "Daniel",
    byMeasurementType: false,
    data: null,
    drawer: false,
    jwtToken: null,
    loading: true,
    name: "Moist Cloud",
    owners: [],
    snackbar: false,
  }),

  methods: {
    authenticate() {
      firebase
        .auth()
        .signInAnonymously()
        .then((userCredential) => {
          userCredential.user
            .getIdToken()
            .then((token) => {
              this.jwtToken = token;
            })
            .catch((error) => {
              console.log(
                "Get JWT Error: " + error.code + "\n" + error.message
              );
            })
            .finally(() => this.getOwners());
        })
        .catch((error) => {
          console.log("Sign-in Error: " + error.code + "\n" + error.message);
        });
    },
    getData(owner) {
      const daysToGetDataFrom = 7;
      const date = new Date();
      date.setDate(date.getDate() - daysToGetDataFrom);

      const url =
        apiUrl +
        "/measurements?owner=" +
        owner +
        "&afterDate=" +
        date.toISOString().split("T")[0];

      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${this.jwtToken}`,
          },
        })
        .then((response) => {
          this.data = response.data;
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
          this.activeOwner = owner;
          this.snackbar = true;
          // console.log("data:");
          // console.log(this.data);
        });
    },
    getOwners() {
      const url = apiUrl + "/owners";

      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${this.jwtToken}`,
          },
        })
        .then((response) => {
          this.owners = response.data;
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.getData(this.activeOwner);
        });
    },
    reloadData(owner) {
      this.drawer = false;
      this.loading = true;
      this.getData(owner);
    },
    setByMeasurementType(byMeasurementType) {
      this.drawer = false;
      this.byMeasurementType = byMeasurementType;
    },
  },

  watch: {
    activeOwner(newOwner) {
      localStorage.activeOwner = newOwner;
    }
  }
};
</script>